const bannerTextList = [
  {
    title: '线上线下一体化管理软件',
    description: '支持门店、线上、线下互通互联<br />实现商品通、营销通、会员通<br />帮助商家更好的卖货<br />'
  },
  {
    title: '电商ERP 就用店货通',
    description: '店货通ERP, 支持免费试用, 不限使用人数<br />支持多店铺库存统一管理, 智能审单<br /> 高效配货, 订单管理和仓储管理一体化<br />'
  },
  {
    title: '新零售店铺管理解决方案',
    description: '全面实现从多店铺商品、进销存、<br/> 门店管理、订单跟踪、账款管理、<br/>供应链的一站式专业的便捷服务<br/>'
  }
]

const storeList = [
    {
        icon: 'icondianshangpingtaikaifa',
        title: '多店铺一体化管理',
        content: '多店铺统一管理，线上线下协同运营、服 务打通，积分同步共享、多门店价格管控， 货款统一结算，一套系统实现门店管理， 不需要换硬件设备，节约成本'
    },
    {
        icon: 'iconweibiaoti-1',
        title: '订单自动化高效管理',
        content: '自动同步追踪订单，系统智能审单、派单、 分配仓库、支持扫描验货出库，降低错发、 漏发概率，高效提升大促订单处理效率'
    },
    {
        icon: 'iconwuzicangku',
        title: '订单分仓和订单缺货管理',
        content: '定制仓储库区规划、精细化库位规划、异地 分仓订单管理，按订单地址及仓库系统自动 识别缺货订单'
    },
    {
        icon: 'iconhuabanfuben',
        title: '多店铺库存实时同步',
        content: '自动同步多仓库多店铺库存数据，单品 及套装实时库存精准统计，根据库存销 量设置上下限预警，便于及时补货，有 效防止缺货超卖'
    },
    {
        icon: 'iconshangpinSKU',
        title: '商品SKU统一管理',
        content: '商品分类管理，SKU统一编码，支持 条形码自定义设置管理，支持商品出 入库、盘点等业务的快速扫描处理'
    },

    {
        icon: 'iconwanglaimingxizhang',
        title: '财务往来对账清晰快捷',
        content: '自动生成财务报表，快速管理应收、应付款 项，物流运费快速结算，供应商对账快速准 确，财务往来清晰明了'
    },
    {
        icon: 'iconico_yiyuanguanjia_yinshianpai',
        title: '多级别权限在线审批',
        content: '可以设置多层级别审批、不同的权限，支持 多人会审，电脑或手机均可进行审批操作， 远离纸质办公，更加快捷方便'
    },
    {
        icon: 'iconico_xitongshezhi_xiangmuweihu',
        title: '自定义字段设置',
        content: '解决个性化业务需求，任意灵活添加文 本、数值、日期、下拉菜单等各种字段和计 算公式，轻松打造企业个性化专属业务流程'
    },
    {
        icon: 'iconzidongqujia',
        title: '设定系统自动取价',
        content: '设置多种自动取价方式解放双手，价格策略 应对多层级客户、多级别货品类型的价格， 更加省时省心'
    },
    {
        icon: 'iconbaobiaofenxi',
        title: '精益订单售后查询分析',
        content: '能够及时掌握线上订单的售后类型以及信息 问题，严谨细致的售后分析，可以让你及时 找出问题的根源所在，帮你解除后顾之忧'
    },
    {
        icon: 'iconicon-test',
        title: '操作快速上手',
        content: '界面简单、清晰易懂，初始数据轻松 导入，系统自带新手教程视频助您快 速掌握，“零”基础也能轻松掌握'
    },
    {
        icon: 'iconico_zongkuguanli_wupinkucunyujing',
        title: '库存锁定和预警',
        content: '免去多部门同时调用同一货品的尴尬， 还原货品真实的可用库存,库存数量过 多过少，系统自动提醒'
    },
]
const productAdvantageList = [
    {
        icon: 'iconxitongwending1',
        backgroundImage: 'linear-gradient(to bottom right, #5f9af1 , #6dc4fa)',
        title: '系统稳定',
        description: '零卡顿、零漏单<br/>平稳承载多次购物狂欢节',
    },
    {
        icon: 'iconanquanjianshi',
        backgroundImage: 'linear-gradient(to bottom right, #0ecfda , #5eebb9)',
        title: '安全坚实',
        description: '聚石塔安全认证<br/>全面保护企业数据安全',
    },
    {
        icon: 'iconcaozuojiandan1',
        backgroundImage: 'linear-gradient(to bottom right, #8670ff , #ceb0fc)',
        title: '操作简单',
        description: '初始化仅需3—5分钟<br/>零基础新手当天亦可上手',
    },
    {
        icon: 'iconSaaS1',
        backgroundImage: 'linear-gradient(to bottom right, #ff9b65 , #ffc647)',
        title: 'SaaS化',
        description: '行业内超高性价比<br/>无需部署，多端随时访问',
    },
    {
        icon: 'iconjishuguoying1',
        backgroundImage: 'linear-gradient(to bottom right, #ff6f95 , #ff9396)',
        title: '技术过硬',
        description: '京东腾讯阿里团队<br/>十多年行业技术沉淀',
    },
    {
        icon: 'iconkehufuwu1',
        backgroundImage: 'linear-gradient(to bottom right, #47b3fe , #08ebfe)',
        title: '客户服务',
        description: '专属客服专家一对一<br/>真诚伴随用户使用全过程',
    },
]

const classicCase = [
    {
        icon: 'iconxiebao-gaogenxie',
        title: '服装鞋帽',
        articleTitle: '阿卡莎女鞋执行方案',
        articleContent: '由于阿卡莎这个品牌，在众多电商平台都开设了自己的店铺。在多 平台店铺运营过程中，出现了成本把控差、毛利率不清晰、数据统 计难、账目混乱等问题。通过使用一段店货通财务软件后，阿卡莎 此前遇到的各种财务问题都得到了解决。',
        coreList: [
            '多店铺财务数据统一管理，盈亏状况快速及时了解。',
            '解决了账目混乱问题，有效避免出错率。',
            '财务报表统一查询和核对，财务数据自动汇总、清晰明了，节 省人力和时间。'
        ]
    },
    {
        icon: 'iconnaiping',
        title: '母婴玩具',
        articleTitle: '嘟嘟母婴专营店执行方案',
        articleContent: '嘟嘟母婴刚开始和店货通合作的时候，更多的合作需求是多平台共同打快递单。对于10多个店铺的商家来说，如果能通过第三方软件来把所有平台的订单整合到一起进行处理，那无疑是会给商家带来巨大的便利的。后来，随着经营的业务越来越广，公司开始使用店货通的财务报表功能，节省了不必要的人力成本。',
        coreList: [
            '打单处理速度提高了，防止出错。',
            '智能审批，更有效的减少了人工开支。',
            '财务报表统一查询和核对，财务数据清晰明了，节省人力。'
        ]
    },
    {
        icon: 'iconshouji',
        title: '手机数码',
        articleTitle: '神舟大本航专卖店执行方案',
        articleContent: '深圳市大本航科技有限公司是专业从事联想、IBM、神舟等品牌笔记本电脑 、台式机、一体机、服务器，于线上线下一体的销售商家。面对财务和仓库人员数据频繁出错，大本航老总开始使用——店货通ERP。 店货通ERP的组合商品功能，解决了大本航当前遇到的最大问题。',
        coreList: [
            '店货通ERP组合商品功能，为电脑和配件做好关联，提高了工作效率。',
            '自动扣减系统库存，有效防止库存数据出错。',
            '自动核算财务、库存，节省了人力成本。'
        ]
    },
]
export default {
    bannerTextList,
    storeList,
    productAdvantageList,
    classicCase
}