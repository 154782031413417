import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import mmodalStyles from '../styles/mmodal.module.less';

/* const _once = (fn) => {
    let _isrun = true;
    return () => {
        if (_isrun) {
            _isrun = false;
            fn();
        }
    };
}; */

/* eslint-disable jsx-a11y/no-static-element-interactions */
const MModal = (props) => {
    const { visible, title, onClosed, onCancel, onConfirm } = props;

    const handleTouchmove = (e) => e.preventDefault();

    useEffect(() => {
        const eleTouchmoveStop = document.querySelector('.touchmove-stop');
        if (visible) {
            // 处理滚动穿透
            eleTouchmoveStop.addEventListener('touchmove', handleTouchmove, false);
        }
    }, [visible]);

    const onWrapClick = (e) => {
        if (e.target === e.currentTarget) {
            onClosed();
        }
    };
    const onKeyDown = (e) => {
        console.log(e);
    };

    const children = (
        <section className={mmodalStyles.mmodalRoot}>
            <div className={`${mmodalStyles.mmodalMask}`}></div>
            <div
                className={`touchmove-stop ${mmodalStyles.mmodalWrapper}`}
                onClick={onWrapClick}
                onKeyDown={onKeyDown}
            >
                <div className={mmodalStyles.mmodalContent}>
                    <div className={mmodalStyles.mmodalHeader}>{title}</div>
                    <div className={mmodalStyles.mmodalBody}>{props.children}</div>
                    <div className={mmodalStyles.mmodalFooter}>
                        <button onClick={onCancel}>取消</button>
                        <button className={mmodalStyles.mmodal__confirm} onClick={onConfirm}>
                            提交
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );

    return visible ? ReactDOM.createPortal(children, document.querySelector('body')) : null;
};

MModal.propTypes = {
    visible: PropTypes.bool,
    title: PropTypes.string || PropTypes.element,
    onClosed: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};

MModal.defaultProps = {
    visible: false
};

export default MModal;
