import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Swiper from 'react-id-swiper';
import message from 'antd/es/message';

import Icon from '../components/icon';
import SEO from '../components/seo';
import MModal from '../components/MModal';

import homeStore from '../store/home';
import { sendEmail } from '../api/server';

import 'swiper/css/swiper.css';
import '../styles/mobile.less';
import mobileStyles from '../styles/mobile.module.less';

const storeListColor = [
    '#daedfc',
    '#e0fdf8',
    '#fae7c9',
    '#fde8fb',
    '#ffd3d2',
    '#fdffe7',
    '#fde8fb',
    '#dbe2fc',
    '#fdffe7',
    '#fae7c9',
    '#daedfc',
    '#e0fdf8'
];

export default () => {
    const [visible, setVisible] = useState(false);
    const [formData, setFormData] = useState({
        clientName: '',
        fullName: '',
        mobile: ''
    });
    const data = useStaticQuery(graphql`
        query {
            banner: file(relativePath: { eq: "mobile/banner.jpg" }) {
                childImageSharp {
                    fluid(quality: 50, maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            powerfulBg: file(relativePath: { eq: "mobile/powerful_bg.jpg" }) {
                childImageSharp {
                    fluid(quality: 50, maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            powerful: file(relativePath: { eq: "mobile/powerful.png" }) {
                childImageSharp {
                    fixed(width: 260) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            cases: allFile(
                sort: { fields: [name], order: ASC }
                filter: { relativePath: { regex: "images/mobile/case(\\\\d+).jpg$/" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 350) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `);

    const handlerModal = (e, key) => {
        setFormData({ ...formData, [key]: e.target.value });
    };

    const onConfirm = async () => {        
        if (!formData.fullName || !formData.mobile || !formData.clientName) {
            // console.log('return');
            return;
        }
        let pattern = /^1(3|4|5|6|7|8)\d{9}$/;
        if (!pattern.test(formData.mobile)) {
            message.warn('请填写合法的手机号');
            return;
        }
        await sendEmail({
            ...formData,
            to: 'wangyang@guojing.net',
            productName: '店货通'
        });
        message.success('申请成功');
        setVisible(false);
    };

    return (
        <section className={mobileStyles.wrapper}>
            <SEO
                title="首页"
                meta={[
                    {
                        name: 'viewport',
                        content:
                            'width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no'
                    }
                ]}
            />
            <BackgroundImage fluid={data.banner.childImageSharp.fluid} loading="eager">
                <div className={mobileStyles.banner}>
                    <h2>
                        <Icon type="iconweibiaoti-3" />
                        店货通
                    </h2>
                    <h3>
                        线上线下一体化管理软件
                        <br />
                        <span className={mobileStyles.bannerTips}>让每个企业都能</span><span className={mobileStyles.bannerImportant}>"提升效率"</span> 
                    </h3>
                    <button onClick={() => setVisible(true)}>申请体验</button>
                </div>
            </BackgroundImage>

            <Title text="无需实施安装 · 功能强大全面" />
            <Row gutter={[12, 12]} style={{ padding: '0 2em' }}>
                {homeStore.storeList.map((item, index) => (
                    <Col key={index} span={12}>
                        <div
                            className={mobileStyles.fullUpgradeItem}
                            style={{ background: storeListColor[index] }}
                        >
                            <Icon type={item.icon} style={{ color: '#08c' }} />
                            <strong>{item.title}</strong>
                            <p>{item.content}</p>
                        </div>
                    </Col>
                ))}
            </Row>
            <BackgroundImage
                fluid={data.powerfulBg.childImageSharp.fluid}
                style={{ textAlign: 'center', padding: '2em' }}
            >
                <Img fixed={data.powerful.childImageSharp.fixed} />
            </BackgroundImage>

            <Title text="专业的产品 · 过硬的技术实力" />
            <Row gutter={[16, 20]}>
                {homeStore.productAdvantageList.map((item, index) => (
                    <Col key={index} span={8}>
                        <div className={mobileStyles.safeguardItem}>
                            <Icon
                                className={mobileStyles.safeguardItem__icon}
                                type={item.icon}
                                style={{
                                    backgroundImage: item.backgroundImage
                                }}
                            />
                            <div className={mobileStyles.safeguardItem__title}>{item.title}</div>
                            <div
                                className={mobileStyles.safeguardItem__description}
                                dangerouslySetInnerHTML={{
                                    __html: item.description
                                }}
                            ></div>
                        </div>
                    </Col>
                ))}
            </Row>

            <div className={mobileStyles.caseWrapper}>
                <Title text="经典案例 · 来自用户的心声" />
                <Swiper
                    pagination={{
                        el: '.swiper-pagination__case',
                        type: 'bullets',
                        clickable: true
                    }}
                    containerClass={`swiper-container ${mobileStyles.caseItemBanner}`}
                    spaceBetween={0}
                    // autoplay={{
                    //     delay: 3000,
                    //     disableOnInteraction: false
                    // }}
                >
                    {homeStore.classicCase.map((item, index) => (
                        <div key={index} className={mobileStyles.caseItem}>
                            <Img
                                fixed={data.cases.nodes[index].childImageSharp.fixed}
                                className={mobileStyles.caseItemImg}
                                loading="eager"
                            />
                            <div className={mobileStyles.caseItemContent}>
                                <h4>{item.articleTitle}</h4>
                                <p>{item.articleContent}</p>
                                <dl>
                                    <dt>核心价值</dt>
                                    {item.coreList.map((core, index) => (
                                        <dd key={index}>{core}</dd>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    ))}
                </Swiper>
            </div>

            <footer className={mobileStyles.footer}>
                <div>
                    <button
                        onClick={() =>
                            window.open('http://q.url.cn/cdPt4e?_type=wpa&qidian=true', '_blank')
                        }
                    >
                        <Icon type="iconzaixianzixun" />
                        &nbsp;在线客服
                    </button>
                    <div>&nbsp;</div>
                    <button onClick={() => window.open('tel:400-000-2900', '_self')}>
                        <Icon type="icondianhua1" />
                        &nbsp;电话咨询
                    </button>
                </div>
                <button onClick={() => setVisible(true)}>申请体验</button>
            </footer>
            <MModal
                title="店货通申请体验"
                visible={visible}
                onClosed={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                onConfirm={onConfirm}
            >
                <input
                    type="text"
                    placeholder="请输入企业名称"
                    value={formData.clientName}
                    onChange={(e) => handlerModal(e, 'clientName')}
                />
                <input
                    type="text"
                    placeholder="请输入联系人"
                    value={formData.fullName}
                    onChange={(e) => handlerModal(e, 'fullName')}
                />
                <input
                    type="text"
                    placeholder="请输入联系电话"
                    value={formData.mobile}
                    onChange={(e) => handlerModal(e, 'mobile')}
                />
            </MModal>
        </section>
    );
};

const Title = (props) => {
    return <h2 className={mobileStyles.sectionTitle}>{props.text}</h2>;
};
